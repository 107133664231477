<template>
    <div class="rb-view">
        <el-form :model="form_data" :ref="form_name" :rules="form_rules" size="mini" label-width="200px" style="max-width: 800px" inline>
            <el-row>
                <el-col>
                    <el-form-item label="支付超时设置(秒)" prop="pay_timeout_seconds" required>
                        <el-input-number v-model="form_data.pay_timeout_seconds" :min="30" :max="24*60*60"></el-input-number>
                        <div class="rb-input-tip">提交订单多久没支付取消订单,最低设置5分钟,最多24小时</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="可提前核销时间(秒)" prop="pre_verify_seconds" required>
                        <el-input-number v-model="form_data.pre_verify_seconds" :min="0" :max="2*60*60"></el-input-number>
                        <div class="rb-input-tip">已预约订单提前可开场前进行核销,最低0秒,最多提前2小时</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="可在开课前多少秒退款(秒)" prop="pre_refund_seconds" required>
                        <el-input-number v-model="form_data.pre_refund_seconds" :min="0"></el-input-number>
                        <div class="rb-input-tip">已预约订单提前可再开场前进行退款,最低0秒,开课后不允许退款</div>
                    </el-form-item>
                </el-col>
            </el-row>
<!--            <el-row>-->
<!--                <el-col>-->
<!--                    <el-form-item label="体验包核销有效时间(秒)" prop="experience_verify_timeout_seconds" required>-->
<!--                        <el-input-number v-model="form_data.experience_verify_timeout_seconds" :min="0" :max="60*60*24*60"></el-input-number>-->
<!--                        <div class="rb-input-tip">购买试玩体验包后体验包的有效时间,在有效时间内可以进行和核销,最低0秒,最多提前60天</div>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--            </el-row>-->


            <div style="display:flex;flex-direction:row;justify-content:flex-end;width:100%;">
                <div style="float:right;">
                    <el-button @click="handleSubmitTap(form_name)" size="mini" type="primary">保存</el-button>
                </div>
            </div>

        </el-form>


    </div>
</template>

<script>
    import {getOrderPlaySetting,setOrderPlaySetting} from "@/api/hado/setting";

    export default {
        name: "order-play-setting",
        data(){
            return{
                form_name:'order_play_setting_form',
                form_data:{},
                form_rules:{
                    pay_timeout_seconds:[{required:true,message:'请输入支付超时设置',trigger:'change'}],
                    pre_verify_seconds:[{required:true,message:'请输入可提前核销时间',trigger:'change'}],
                    experience_verify_timeout_seconds:[{required:true,message:'请输入体验券核销有效时间',trigger:'change'}],
                    pre_refund_seconds:[{required:true,message:'请输入开课前可退款时间',trigger:'change'}]
                },
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage(){
                getOrderPlaySetting().then(resp=>{
                    console.log('resp:',resp)
                    this.form_data = resp.data
                })
            },
            reloadPage(){
                this.initPage()
            },
            handleSubmitTap(form_name) {
                this.$refs[form_name].validate((valid)=>{
                    if(valid){
                        setOrderPlaySetting(this.form_data).then(()=>{
                            this.reloadPage()
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .rb-input-tip{
        color: #969799;
        line-height: 14px;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0;
    }
</style>
